<template>
  <div>
    <font-awesome-icon icon="truck" class="mr-2" v-if="data.direct_delivery" />
    <strong>{{ getProductName() }}</strong>
    <br />
    <span>{{ getVariantName() }}</span>
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  name: "ProductsTableName",

  computed: {
    lang: function () {
      return this.$i18n.locale;
    },
  },

  methods: {
    getProductName() {
      return get(
        this.data,
        `ppm.lot.productproducer.translations.${this.lang}.name`
      );
    },
    getVariantName() {
      return get(this.data, `ppm.lot.translations.${this.lang}.name`);
    },
  },
};
</script>
